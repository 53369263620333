import { Swiper, Navigation, Pagination, Autoplay } from 'swiper/js/swiper.esm.js';
Swiper.use([ Navigation, Pagination, Autoplay ]);

$(function () {
	/*/
	 *  Swiper
	/*/
		let swiperBannerCount = document.querySelectorAll('[ps-banner] .swiper-slide').length <= 1 ? false : true

		let swiperBanner = new Swiper('[ps-banner]', {
			loop: swiperBannerCount,
			speed: 500, 
			slidesPerView: 1,
			autoHeight: false,
			observer: true,
			watchOverflow: true,
			threshold: 5,
			autoplay: {
				delay: 4000,
				disableOnInteraction: true
			},
			pagination: {
				el: '.swiper-dots',
				type: 'bullets',
				clickable: false,
				bulletClass: 'swiper-dot',
				bulletActiveClass: 'swiper-dot_active',
				lockClass: 'd-none'
			},
			navigation: {
				nextEl: '.swiper-arrow-next',
				prevEl: '.swiper-arrow-prev',
				lockClass: 'd-none',
				disabledClass: 'swiper-arrow_disabled'
			},
		})
})